import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const HelpArticleTemplate = ({ content, contentComponent, title }) => {
  const ArticleContent = contentComponent || Content;

  return (
    <section class="article">
      <div class="o-content-container">
        <h3 id="getting-started-with-tuskle">{title}</h3>

        <ArticleContent content={content} />
      </div>
    </section>
  );
};

const HelpArticle = ({ data }) => {
  const { markdownRemark: article } = data;

  return (
    <Layout>
      <div className="o-content-container" style={{ textAlign: "left" }}>
        <a href="/help">Back to Help</a>
      </div>
      <HelpArticleTemplate
        title={article.frontmatter.title}
        content={article.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

HelpArticle.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default HelpArticle;

export const pageQuery = graphql`
  query HelpArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;
